import './App.css';
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import ChatPage from './pages/ChatPage';
import ComingSoon from './pages/ComingSoon';

function App() {
  return <Routes>
    <Route path='/' element={<HomePage />} />
    <Route path='/chat' element={<ChatPage />} />
    <Route path='/coming-soon' element={<ComingSoon />} />
  </Routes>
}

export default App;
